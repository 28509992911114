"use client";

import { useRef } from "react";
import { RelayEnvironmentProvider } from "react-relay";

import makeEnvironment, {
  ensureSubscriptionClientConnected,
  makeSubscriptionClient,
} from "relay/jwtEnvironment";
import { relayContext } from "relay/relayContext";

import { useAuthStore } from "./authStore";
import { setRelayEnvironment } from "./o/[orgId]/(feature-flagged)/(real-time-ops)/_relay/relayEnvironmentManager";

import type { ReactNode } from "react";
import type { SubscriptionClient } from "subscriptions-transport-ws";
import type { Environment } from "relay-runtime";

export function RelayProvider({ children }: { children: ReactNode }) {
  const { checkAuth, resetAuth } = useAuthStore(store => store);
  const environmentRef = useRef<Environment>();
  const subscriptionClientRef = useRef<SubscriptionClient>();

  if (!subscriptionClientRef.current) {
    subscriptionClientRef.current = makeSubscriptionClient(resetAuth);
    if (typeof window !== "undefined") {
      // When the user brings the tab/browser back into visibility, check if the subscription websocket is
      // still alive.  Inspired by
      // https://levelup.gitconnected.com/high-resilience-with-web-sockets-315641e4f02c
      document.addEventListener("visibilitychange", () => {
        if (!document.hidden) {
          ensureSubscriptionClientConnected(subscriptionClientRef.current!);
        }
      });

      // If the browser comes back online (likely from a network disruption), check that the subscription
      // client is connected.  Inspired by
      // https://levelup.gitconnected.com/high-resilience-with-web-sockets-315641e4f02c
      window.addEventListener("online", () => {
        ensureSubscriptionClientConnected(subscriptionClientRef.current!);
      });
    }
  }

  if (!environmentRef.current) {
    environmentRef.current = makeEnvironment({
      subscriptionClient: subscriptionClientRef.current,
      onJwtExpired: checkAuth,
    });
    // Set the environment in the relayEnvironmentManager
    setRelayEnvironment(environmentRef.current);
  }

  return (
    <relayContext.Provider
      value={{
        subscriptionClient: subscriptionClientRef.current,
      }}
    >
      <RelayEnvironmentProvider environment={environmentRef.current}>
        {children}
      </RelayEnvironmentProvider>
    </relayContext.Provider>
  );
}
